// middleware/auth.ts
import { useUserStore } from '~/store/users'

export default defineNuxtRouteMiddleware(async (to, from) => {

    const userStore = useUserStore()

    // Ensure the store is initialized
    if (userStore.permissions.length === 0) {
        await userStore.whoami()
    }

    const requiredPermissions = to.meta.requiredPermissions || []

    const checkPermissionsWithRetry = async (retries: number, delay: number): Promise<boolean> => {
        for (let i = 0; i < retries; i++) {
            const hasPermission = requiredPermissions.every(permission => userStore.permissions.includes(permission))
            if (hasPermission || userStore.permissions.length > 0) {
                return hasPermission
            }
            await new Promise(resolve => setTimeout(resolve, delay))
        }
        return false
    }

    // Initial permission check
    let hasPermission = requiredPermissions.every(permission => userStore.permissions.includes(permission))

    // Retry if no permissions initially
    if (requiredPermissions.length > 0 && !hasPermission && userStore.permissions.length === 0) {
        hasPermission = await checkPermissionsWithRetry(3, 200) // Retry 3 times with 200ms delay
    }

    // Final permission check
    if (requiredPermissions.length > 0 && !hasPermission) {
        throw createError({
            statusCode: 403,
            statusMessage: 'Forbidden',
        })
    }
})
